import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
  isLoggedIn: false,
  currentUser: { },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    
    loginUser: (state, {payload}) => {
      state.isLoggedIn = true;
      state.currentUser = payload;
    },

    logoutUser: (state) => {

      state.isLoggedIn = false;
      state.currentUser = {};

    },
    
  }
});

export const { loginUser, logoutUser} = authSlice.actions;

export default authSlice.reducer;