import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'react-image-crop/dist/ReactCrop.css';
import { ToastContainer, toast } from 'react-toastify';

//Dashboard Imports
import { CSpinner, useColorModes } from '@coreui/react'

// Containers
//import DefaultLayout from './layout/DefaultLayout'
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const ForgotPassword = React.lazy(() => import('./views/password/ForgotPassword'))
const Zone = React.lazy(() => import('./views/zones/Zones'))
const Schedule = React.lazy(() => import('./views/schedules/Schedules'))

//Component
const App = () => {

   return (
      <BrowserRouter>
         <ToastContainer />
         <Suspense
            fallback={
               <div className="pt-3 text-center">
                  <CSpinner color="primary" variant="grow" />
               </div>
            }
         >
            <Routes>
               <Route exact path="/login" name="Login Page" element={<Login />} />
               <Route exact path="/password" name="Password Page" element={<ForgotPassword />} />
               <Route exact path="/events/:idEvent/zone/:idZone" name="Event and Zone" element={<Zone />} />
               <Route exact path="/schedules/:idEvent" name="Event and Zone" element={<Schedule />} />
               <Route path="*" element={<DefaultLayout />} />
            </Routes>
         </Suspense>
      </BrowserRouter >
   )
}

export default App
