import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from '../locales/en/translation.json'
import translationES from '../locales/es/translation.json'
import translationPT from '../locales/pt/translation.json'
const resources = {
	en: { translation: translationEN },
	es: { translation: translationES },
	pt: { translation: translationPT }
}

const storedLanguage = localStorage.getItem('language') || 'en';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: storedLanguage,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			useSuspense: true,
		},
	});

// Almacenar el idioma seleccionado en localStorage cada vez que cambie
i18n.on('languageChanged', (lng) => {
	localStorage.setItem('language', lng);
});

export default i18n;